<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet
        :title="'Create Community Activities'"
        :canBack="true"
        colorIcon="#052633"
      />
      <div class="mt-4 d-flex align-center justify-center full_w">
        <div class="_temp_list_medium">
          <div>
            <h4>Nama Activities</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama activities disini"
              v-model="field.nama_activities"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="mt-3">
            <h4>Tanggal Pelaksanaan</h4>
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="rounded-lg"
                  v-model="field.tgl_pelaksanaan"
                  prepend-inner-icon="mdi-calendar-range"
                  readonly
                  placeholder="Tanggal transaksi"
                  hide-details
                  dense
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="field.tgl_pelaksanaan"
                @input="closeDateMenu(field.tgl_pelaksanaan)"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="mt-3">
            <h4>Metode</h4>
            <v-radio-group
              v-model="field.metode"
              hide-details
              class="ma-0"
              dense
              row
            >
              <v-radio label="Online" color="#052633" value="online"></v-radio>
              <v-radio
                label="Offline"
                color="#052633"
                value="offline"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="mt-3">
            <h4>Tujuan</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama activities disini"
              v-model="field.tujuan"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="mt-3">
            <h4>Lokasi/Link</h4>
            <v-textarea
              rows="3"
              auto-grow
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama activities disini"
              v-model="field.lokasi"
              color="#0D47A1"
              dense
              hide-details
            ></v-textarea>
          </div>
          <div class="mt-3">
            <h4>Pilih Instansi Partner</h4>
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg mt-2"
              placeholder="Search for Instansi"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
            <div class="mt-3 d-flex align-center flex-wrap">
              <v-card
                class="mr-2"
                min-width="100px"
                flat
                color="#052633"
                v-for="(item, n) in field.partner"
                :key="n"
                dark
                rounded="xl"
                style="flex-grow: 1"
              >
                <div class="d-flex align-center justify-center px-3">
                  {{ item.nama_instansi }}
                  <v-btn small class="ml-2" icon depressed @click="remove(n)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </div>
            <v-data-table
              v-if="dataSet"
              v-model="field.partner"
              :headers="headers"
              item-key="id"
              :items="dataSet"
              show-select
              class="elevation-0 mt-3"
              hide-default-footer
              :single-select="false"
            >
            </v-data-table>
          </div>
          <div class="d-flex align-center justify-end mt-4">
            <v-btn
              :disabled="loadingSave ? true:false"
              depressed
              class="text-capitalize bg_default"
              @click="createData"
              :dark="!loadingSave ? true:false"
            >
              <h4 v-if="!loadingSave">Simpan</h4>
              <h4 v-if="loadingSave">Loading</h4>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "createCommunityActivites",
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      loading: false,
      loadingSave: false,
      menuDate: false,
      find: "",
      dataSet: null,
      field: {
        nama_activities: "",
        tgl_pelaksanaan: "",
        metode: "",
        tujuan: "",
        lokasi: "",
        partner: [],
      },
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Community Activities",
          disabled: false,
          href: "/admin/cd/community-activities",
        },
        {
          text: "Create Community Activities",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Instansi Partner", value: "nama_instansi" },
        { text: "Email", value: "email" },
        { text: "Alamat", value: "alamat" },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    closeDateMenu(item) {
      this.menuDate = false;
      this.field.tgl_pelaksanaan == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/instansi-partner?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[nama_instansi]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    createData() {
      this.loadingSave = true;
      let isEmpty = false;
      let arrIdInstantiParnter = [];
      for (const item in this.field) {
        if (!this.field[item]) {
          if (
            item == "nama_activities" ||
            item == "tgl_pelaksanaan" ||
            item == "metode"
          ) {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }
      this.field.partner.forEach(item => {
        arrIdInstantiParnter.push(item.id);
      });
      if (!isEmpty) {
        let data = {
          body: {
            klien_id: this.profile.data.profile.klien_id,
            nama: this.field.nama_activities,
            tanggal_pelaksanaan: this.field.tgl_pelaksanaan,
            metode: this.field.metode,
            tujuan: this.field.tujuan,
            lokasi: this.field.lokasi,
            instansi_partner: JSON.stringify(arrIdInstantiParnter),
          },
          path: `sysadmin/admin/community-development/event/create`,
        };
        if (arrIdInstantiParnter.length == 0) {
          delete data.body.instansi_partner;
        }
        this.$store
          .dispatch("client/postApi", data)
          .then((data) => {
            if (data.status == "success") {
              for (let i in this.field) {
                this.field[i] = "";
                if(i == 'partner'){
                  this.field[i] = [];
                }
              }
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
            } else {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              if (key.length == 0) {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            }
          })
          .catch((e) => {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          });
      }

      this.loadingSave = false;
    },
    remove(idx) {
      this.field.partner.splice(idx, 1);
    },
  },
};
</script>

<style></style>
